import React, { ReactElement } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { ReactComponentLike } from "prop-types";

interface RoomDef {
  roomId: string;
  roomName: string;
  shortDescription?: string;
  occupancy: string;
  size: string;
  views: string | Array<string>;
  link: string;
  imageUrl: string;
  amenities: Array<string>;
  longDescription: string;
  caption: string;
  images: Array<string>;
}
export type RoomDefArray = Array<RoomDef>;
export type GetRoomFn = (roomId: string) => RoomDef;

const RoomDataProvider: ReactComponentLike = ({
  children,
}: {
  children: (roomData: RoomDefArray, getRoom: GetRoomFn) => ReactElement;
}) => {
  const { t } = useTranslation();
  const standardRoomAmenities = [
    "Air Conditioning",
    "IDD Telephone",
    "Full Toiletries and Bath Amenities",
    "Tea and Coffee set up with Kettle",
    "In Room Safe",
    "Two Bath-robes",
    "Smart Room Key",
    "Free WIFI",
    "32 inch T.V. with Cable",
    "Mini Bar",
    "Complimentary Bottled Water",
    "Desk and Make-Up Table",
    "Shower",
  ];
  const roomData: RoomDefArray = [
    {
      roomId: "luxury",
      roomName: t("Luxury"),
      occupancy: t("2 People"),
      size: t("50 M² (538 ft²)"),
      views: t("River"),
      longDescription: t(
        "Interiors with modern decorations open out onto private expansive balconies which, feature truly impressive views of Hoai River. Ideal for romantic escapes, the Laluna Luxury offers the comfort of a luxurious King Bed in wonderfully decorated 50-sq-m with elegant touches and thoughtful amenities. Unwind in the comfort bathroom features the walk-in shower, bathtub and toilets. Stretch out on the sofa and stay entertained."
      ),
      caption: t("Ideal for Romantic Escapes"),
      link: "/rooms/luxury",
      imageUrl: "/assets/rooms/Luxury/Luxury-1.jpg",
      amenities: ["King Bed", ...standardRoomAmenities, "Bathtub"],
      images: [
        "/assets/rooms/Luxury/Luxury-1.jpg",
        "/assets/rooms/Luxury/Luxury-2.jpg",
        "/assets/rooms/Luxury/Luxury-3.jpg",
        "/assets/rooms/Luxury/Luxury-Bed.jpg",
        "/assets/rooms/Luxury/Luxury-Bathroom.jpg",
        "/assets/rooms/Luxury/Luxury-View.jpg",
      ],
    },
    {
      roomId: "suite",
      roomName: t("Suite"),
      occupancy: t("2 People"),
      size: t("60 M² (646 ft²)"),
      views: t("River"),
      link: "/rooms/suite",
      imageUrl: "/assets/rooms/Suite/Suite-1.jpg",
      amenities: ["King Bed", ...standardRoomAmenities, "Bathtub"],
      caption: t("Suite with Panoramic River Views"),
      images: [
        "/assets/rooms/Suite/Suite-1.jpg",
        "/assets/rooms/Suite/Suite-2.jpg",
        "/assets/rooms/Suite/Suite-3.jpg",
        "/assets/rooms/Suite/Suite-4.jpg",
        "/assets/rooms/Suite/Suite-5.jpg",
        "/assets/rooms/Suite/Suite-Bathroom.jpg",
        "/assets/rooms/Suite/Suite-View.jpg",
      ],
      longDescription: t(
        "Featuring an area of 60-sq-m, the Roof-top Suites are designed for ultimate comfort, furnished with a King Bed and stylish sofa bed in the living area. The modern marble bathroom boasts a deep-soaking bathtub and separate glass-enclosed shower. You can enjoy panoramic river views from our Roof-top Suite. All the expected luxuries of a hotel are at your disposal."
      ),
    },
    {
      roomId: "family suite",
      roomName: t("Family Suite"),
      occupancy: t("4 People"),
      size: t("80 M² (861 ft²)"),
      views: t("River"),
      link: "/rooms/family",
      imageUrl: "/assets/rooms/Family/Family-1.jpg",
      amenities: [
        "King Bed / Two Twin Beds",
        ...standardRoomAmenities,
        "Bathtub",
      ],
      caption: t("Ideal For Family Breaks"),
      images: [
        "/assets/rooms/Family/Family-1.jpg",
        "/assets/rooms/Family/Family-2.jpg",
        "/assets/rooms/Family/Family-3.jpg",
        "/assets/rooms/Family/Family-4.jpg",
        "/assets/rooms/Family/Family-5.jpg",
        "/assets/rooms/Family/Family-6.jpg",
        "/assets/rooms/Family/Family-7.jpg",
        "/assets/rooms/Family/Family-Bathroom.jpg",
      ],
      longDescription: t(
        "Ample space to enjoy river view in these generously appointed 80-sq-m family connecting suites. Ideal for family breaks for a real home away from home experience; luxury amenities blend effortlessly with spacious living. The substantial bathrooms with bathtubs and showers or walk-in shower, as well as a choice of one King Bed or two Twin Beds with inter-connecting room for family."
      ),
    },
    {
      roomId: "super deluxe",
      roomName: t("Super Deluxe"),
      occupancy: t("2 People"),
      size: t("40 M² (430 ft²)"),
      views: t("River"),
      link: "/rooms/super-deluxe",
      imageUrl: "/assets/rooms/Super-Deluxe/Super-Deluxe-1.jpg",
      amenities: [
        "Queen Bed / Two Twin Beds",
        ...standardRoomAmenities,
        "Bathtub",
      ],
      caption: t("Spoil Yourself in an Elegant Super Deluxe"),
      images: [
        "/assets/rooms/Super-Deluxe/Superb-Deluxe-Double-305-1.jpg",
        "/assets/rooms/Super-Deluxe/Superb-Deluxe-Double-305-2.jpg",
        "/assets/rooms/Super-Deluxe/Superb-Deluxe-Double-305-3.jpg",
        "/assets/rooms/Super-Deluxe/Superb-Deluxe-Double-305-4.jpg",
        "/assets/rooms/Super-Deluxe/Superb-Deluxe-Double-310-1.jpg",
        "/assets/rooms/Super-Deluxe/Superb-Deluxe-Double-310-3.jpg",
        "/assets/rooms/Super-Deluxe/Superb-Deluxe-Twin-217.jpg",
        "/assets/rooms/Super-Deluxe/Superb-Deluxe-Twin-217-2.jpg",
      ],
      longDescription: t(
        "Offering guest luxurious comfort, the spacious air-conditioned superb deluxe is 40-sq-m in size and features a balcony overlooking Hoai river. The super deluxe also offer exclusive level of comfort with luxurious Queen Bed or two Twin Beds. Several also have a separate walk-in shower, bathtub and toilets."
      ),
    },
    {
      roomId: "deluxe",
      roomName: t("Deluxe"),
      occupancy: t("2 People"),
      size: t("34 M² (366 ft²)"),
      views: [t("Pool"), t("City")],
      link: "/rooms/deluxe",
      imageUrl: "/assets/rooms/Deluxe/Deluxe-Twin-1.jpg",
      amenities: ["King Bed / Two Twin Beds", ...standardRoomAmenities],
      caption: t("Ideal for Short Stays or Business Trips"),
      images: [
        "/assets/rooms/Deluxe/Deluxe-Double-306-1.jpg",
        "/assets/rooms/Deluxe/Deluxe-Double-306-2.jpg",
        "/assets/rooms/Deluxe/Deluxe-Double-306-3.jpg",
        "/assets/rooms/Deluxe/Deluxe-Double-317-1.jpg",
        "/assets/rooms/Deluxe/Deluxe-Double-317-2.jpg",
        "/assets/rooms/Deluxe/Deluxe-Twin-1.jpg",
        "/assets/rooms/Deluxe/Deluxe-Twin-218.jpg",
        "/assets/rooms/Deluxe/Deluxe-Twin-218-3.jpg",
      ],
      longDescription: t(
        "Deluxe rooms are ideal for short stays or business trips. Modern and brightly furnished, with the option of King Bed or two twin beds ensure absolute comfort in this 34-sq-m room. All rooms look out onto the pool and town views and each of these chic spaces features a marble bathroom with a walk-in shower."
      ),
    },
    {
      roomId: "superior",
      roomName: t("Superior"),
      occupancy: t("2 People"),
      size: t("32 M² (344 ft²)"),
      views: t("Interior Facing"),
      link: "/rooms/superior",
      imageUrl: "/assets/rooms/Superior/Superior-Double-1.jpg",
      amenities: ["King Bed / Two Twin Beds", ...standardRoomAmenities],
      caption: t("Cost-Effective Choice For All Travelers"),
      images: [
        "/assets/rooms/Superior/Superior-Double-1.jpg",
        "/assets/rooms/Superior/Superior-Double-313-1.jpg",
        "/assets/rooms/Superior/Superior-Double-313-3.jpg",
        "/assets/rooms/Superior/Superior-Double-313-4.jpg",
        "/assets/rooms/Superior/Superior-Twin-101.jpg",
        "/assets/rooms/Superior/Superior-Twin-101-2.jpg",
        "/assets/rooms/Superior/Superior-Twin-101-3.jpg",
      ],
      longDescription: t(
        "If you are looking for good value accommodation in Hoi An, our superior rooms are the ideal choice. This traditional 32-sq-m guest room is a cost-effective choice for all travelers. King bed or two twin beds come with either a bathtub or walk-in shower equipped with toiletries. Features also include free Wi-Fi, air-condition allowing a truly relaxing vacation."
      ),
    },
  ];

  return (
    children &&
    children(roomData, (roomId) => {
      return roomData.filter((room) => room.roomId === roomId)[0];
    })
  );
};

export default RoomDataProvider;
